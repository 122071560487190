<template>
    <footer class="text-center pt-4 pb-4 mt-auto bg-gray-100 justify-between items-center">
        <a class="text-xs text-gray-500 hover:text-gray-700" href="https://scopejapan.jp" target="_brank">powered by scopejapan</a>
    </footer>
</template>

<style lang="scss">




</style>