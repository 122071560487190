import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios"
import VueAxios from "vue-axios"

import "@/assets/css/index.css";
import "@/assets/css/style.scss";
import dayjs from "dayjs";
import "dayjs/locale/ja";
dayjs.locale("ja");
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(fas, far, fab);
import VueFlatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Japanese } from "flatpickr/dist/l10n/ja.js";



createApp(App)
  .use(store)
  .use(router)
  .use(VueAxios, axios)
  .use(VueFlatPickr)
  .provide("dayjs", dayjs)
  .provide("Japanese", Japanese)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
