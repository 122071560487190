import { useStore } from "vuex"
 
export default function usePlaceList(rowData) {
  const store = useStore()
    const id = rowData.option_id
    const name = rowData.name
  const arr = [];
  let defaultPlaceid = 1
  if (name === "常滑") {
      const target = store.state.places.find((e) => e.name === "常滑")
      defaultPlaceid = target.id
      arr.push(target)
      store.state.places.forEach((e) => {
        if (e.option_id === id && e.name !== "常滑") {
          arr.push(e)
        }
      })
      store.state.places.forEach((e) => {
        if (e.option_id !== id && e.name !== "常滑") {
          arr.push(e);
        }
      })
    }
  if (name === "蒲郡") {
    const target = store.state.places.find((e) => e.name === "蒲郡")
     defaultPlaceid = target.id
      arr.push(target);
      store.state.places.forEach((e) => {
        if (e.option_id === id && e.name !== "蒲郡") {
          arr.push(e)
        }
      });
      store.state.places.forEach((e) => {
        if (e.option_id !== id && e.name !== "蒲郡") {
          arr.push(e)
        }
      });
    }
    if (name !== "蒲郡" && name !== "常滑") {
      store.state.places.forEach((e) => {
        if (e.option_id === id) {
          arr.push(e)
        }
      })
      store.state.places.forEach((e) => {
        if (e.option_id !== id) {
          arr.push(e)
        }
      })
      defaultPlaceid = arr[0].id;
    }
  return { list: arr, defaultPlaceid };
}
