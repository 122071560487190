import { createRouter, createWebHistory } from 'vue-router'
import CalendarView from "../views/CalendarView.vue";
// import CalendarCreate from "../views/Calendar/createView.vue";
// import setRaceCarendar from "../views/setRaceCarendar.vue";

import loginView from "../views/loginView.vue";
import UserView from "../views/UserView.vue";
import DayRaceView from "../views/DayRaceView.vue";
import Store from "@/store/index.js";


const routes = [
  {
    path: "/",
    redirect: "/calendar",
  },
  {
    path: "/login",
    name: "loginView",
    component: loginView,
    meta: { logined: true },
  },
  {
    path: "/calendar",
    name: "CalendarView",
    component: CalendarView,
    meta: { loginCheck: true },
  },
  {
    path: "/show",
    name: "DayRaceView",
    component: DayRaceView,
    meta: { loginCheck: true },
  },
  {
    path: "/user",
    name: "UserView",
    component: UserView,
    meta: { permissionCheck: true },
  },

  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (
    to.matched.some((record) => record.meta.HomeRdirect) &&
    !Store.state.user.id
  ) {
    next({ path: "/login" });
  } else {
    next();
  }
})

router.beforeEach((to, from, next) => {
  if (
    to.matched.some((record) => record.meta.loginCheck) &&
    !Store.state.user.id
  ) {
    next({ path: "/login" });
  } else {
    next();
  }
})

router.beforeEach((to, from, next) => {
  if (
    to.matched.some((record) => record.meta.permissionCheck) &&
    (!Store.state.user.id || Store.state.user.permission < Store.state.admin)
  ) {
    next({ path: "/calendar" });
  } else {
    next();
  }
})
router.beforeEach((to, from, next) => {
  if (
    to.matched.some((record) => record.meta.logined) &&
    Store.state.user.id
  ) {
    next({ path: "/calendar" });
  } else {
    next();
  }
});


export default router
