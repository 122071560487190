import { useStore } from "vuex";
import axios from "axios";
export default async function useGetToken() {
  const store = useStore();
  if (!store.state.token) {
    const formData = new FormData();
    formData.append("getcsrf", "csrf");
    const url = store.state.url + "token.php";
    await axios.post(url, formData)
      .then((response) => {
          store.dispatch('tokenSetAction', response.data)
      })
      .catch((e) => {
          console.log('error', e)
      })
  }
}

