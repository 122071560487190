<template>
    <div class="loading">
        <div class="loading-container">
            <div class="loading-circle"></div>
        </div>
    </div>
</template>


<style lang="scss" scoped>
.loading {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.9);
    left: 0;
    top: 0;
    z-index: 1000;
    font-size: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &-container {
        position: relative;
        display: inline-block;
        box-sizing: border-box;
        padding: 30px;
        width: 25%;
        height: 140px;
        margin: 0 auto;
    }

    &-circle {
        box-sizing: border-box;
        margin: 0 auto;
        width: 80px;
        height: 80px;
        border-radius: 100%;
        border: 10px solid rgb(129, 152, 255);
        border-top-color: rgb(2, 39, 205);
        animation: spin 1s infinite linear;
    }

    @keyframes spin {
        100% {
            transform: rotate(360deg);
        }
    }
}
</style>