<script setup>
import { ref } from 'vue'
import axios from "axios"
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import LoadingView from '@/components/LoadingView.vue'


const isError = ref(false)
const isLoading = ref(false)
const username = ref('')
const password = ref('')
const store = useStore()
const router = useRouter()


const SignIn = async () => {
    isLoading.value = true
    
    const formData = new FormData();
    formData.append("username", username.value)
    formData.append("possword", password.value)
    const url = store.state.url + 'login.php'
    await axios.post(url, formData)
        .then(function (response) {
            if (response.data) {
                isLoading.value = false
                return response.data
            } else {
                return false
            }
        })
        .then(response => {
            if (response) {
                const userJson = JSON.stringify(response)
                if (sessionStorage.getItem('user') === null) {
                    sessionStorage.setItem('user', userJson);
                }
                store.dispatch('setUserAction', response)
                setInit()
                router.push('/calendar')
            } else {
                isLoading.value = false
                alert(`ログインできません。\nログイン情報を再度ご確認ください`)
                
            }

        })
        .catch(function (error) {
            alert(`接続エラーです。\nシステム管理者に連絡してください`)
            console.log('erro', error)
            isLoading.value = false
            return false
        })
}

const setInit = async () => {
    const url = store.state.url + 'init.php'
    const formData = new FormData()
    formData.append("init", "init")
    await axios.post(url, formData)
        .then(response => {
            const places = JSON.stringify(response.data.places)
            const rows = JSON.stringify(response.data.rows)
            const categories = JSON.stringify(response.data.categories)
            const grades = JSON.stringify(response.data.grades)
        
            const options = JSON.stringify(response.data.options)
            if (sessionStorage.getItem('places') === null) {
                sessionStorage.setItem('places', places);
            }
            if (sessionStorage.getItem('rows') === null) {
                sessionStorage.setItem('rows', rows);
            }
            if (sessionStorage.getItem('categories') === null) {
                sessionStorage.setItem('categories', categories);
            }
            if (sessionStorage.getItem('options') === null) {
                sessionStorage.setItem('options', options);
            }
            if (sessionStorage.getItem('grades') === null) {
                sessionStorage.setItem('grades', grades);
            }
           
            store.dispatch("initsetAction")
        })
        .catch((e) => {
            console.log(e)
        })
}
</script>


<template>
    <div>
        <div class="login">
            <div class="login-form">
                <form class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                    <div class="mb-4">
                        <label class="block text-gray-700 text-xs text-left  mb-2" for="username">
                            UserName
                        </label>
                        <input v-model.trim="username"
                            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="username" type="text" placeholder="UserName">
                    </div>
                    <div class="mb-6">
                        <label class="block text-gray-700 text-xs text-left  mb-2" for="password">
                            Password
                        </label>
                        <input v-model.trim="password"
                            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                            id="password" type="password" placeholder="Password">
                        <!-- <p class="text-red-500 text-xs italic">Please choose a password.</p> -->
                    </div>
                    <div v-show="isError" class="bg-red-100 mb-6 p-2 text-xs text-left">
                        <p class="text-red-700">認証エラー！</p>
                        <p class="text-red-700">入力内容をご確認ください。</p>
                    </div>
                    <div class="flex items-center justify-center">
                        <button @click="SignIn"
                            class="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            type="button">
                            Sign In
                        </button>
                    </div>
                </form>
                <p class="text-center text-gray-500 text-xs">
                    &copy;2022 SCOPEJAPAN All rights reserved.
                </p>
            </div>
        </div>
        <div v-if="isLoading">
            <LoadingView />
        </div>
    </div>
</template>


<style lang="scss">

.login{
    width: 100vw;
    height: 100vh;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    &-form{
        border-radius: .5rem;
        width: 300px;
        height: 250px;
        background: white;
    }
}
</style>