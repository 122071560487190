<script setup>

import { reactive, defineEmits, defineProps} from "vue"

const props = defineProps({
    users:Array
})
const userNames = []

const setUserNames = () => {
    props.users.forEach(e => {
        userNames.push(e.name)
    })
}
setUserNames()
const emit = defineEmits(['cancel-event','create-user'])

const data = reactive({
    name:"",
    permission:1,
    password:'',
    rePwd:'',
})

const testpwd = () => {
    const regexp = /^(?=.*[0-9])(?=.*[a-zA-Z])[0-9a-zA-Z]{8,16}$/
    if (!regexp.test(data.password)) {
        alert(`パスワードは半角英数字各1文字以上使用で8～16文字です。`)
        data.password = ''
        data.rePwd = ''
    }
}
const testWNames = ()=>{
    if (userNames.includes(data.name)) {
        alert(`既に使用されています。`)
        data.name = ''
    }
}

const testName = () => {
        const regexp = /^[0-9a-zA-Z]{4,16}$/ 
         if (!regexp.test(data.name)) {
        alert(`nameは半角英数字で4～16文字です。`)
        data.name = ''
    }
}

const chkpwd = () => {
    if (data.password != data.rePwd) {
        alert(`パスワードが一致しません。`)
        data.rePwd = ''
    }
}

const cancel = () => {
    emit('cancel-event')
}
const createUser = () => {
     emit('create-user',data)
}

</script>

<template>
   <div class="modal">
   
         <form class="modal-content bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-[360px]"> 
            <h1 class="text-center text-xl mb-6">新規ユーザー作成</h1>
            <div class="flex my-4">
                 <label class="pl-2 pt-2 text-left block text-gray-700  mb-2 w-1/3" for="Name">
                    Name
                </label>
                <input type="text" @change="testName();testWNames()" v-model.lazy.trim="data.name" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700">
            </div>
            <div class="flex my-4">
                <p class="pl-2 pt-2 text-left block text-gray-700  mb-2 w-1/3">Role</p>
                <div class="flex justify-center text-xs">
                    <label class="flex items-center mr-8"><input type="radio" v-model="data.permission" value="1"/><span class="ml-1">Genaral</span></label>
                    <label class="flex items-center"><input type="radio"  v-model="data.permission" value="10"/><span  class="ml-1">Admin</span></label>
                </div>
            </div>
            <div class="flex my-4 ">
                <p class="pl-2 pt-4 text-left block text-gray-700  mb-2 w-1/3">Password</p>
                <div class="w-full pl-2 mt-2 text-sm" >
                    <p><input  v-model.lazy.trim="data.password"   class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700" type="text" placeholder="パスワード" @change="testpwd()" /></p>
                    <p><input  v-model.lazy.trim="data.rePwd" @change="chkpwd()" class="mt-4 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"  type="text" placeholder="パスワード確認"  /></p>
                </div>
            </div>
             <div class="mt-8 flex justify-center">
                <button
                    class="text-sm bg-gray-400 hover:bg-gray-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2"
                    type="button" @click="cancel">キャンセル</button>
                <button @click="createUser"
                    class="text-sm bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ml-2"
                    type="button">作成</button>
            </div>
        </form>
    </div>
</template>



<style>

</style>