import { inject } from "vue";
export default function useDate(date) {
    const dayjs = inject("dayjs")
    const _date = dayjs(date)
    const day = _date.format('D')
    const week = _date.format("ddd");
    const  w= _date.format("d");
    const fulldate = _date.format('YYYY-MM-DD')
    const month = _date.format('M')
    const year = _date.format('YYYY')
    const start = _date.startOf("month").format("YYYY-MM-DD");
  const end = _date.endOf("month").format("YYYY-MM-DD");
  const plusOneDay = _date.add(1, "d").format("YYYY-MM-DD");
  const minusOneDay = _date.subtract(1, "d").format("YYYY-MM-DD");
  const nextMonth = _date.add(1, "M").startOf("month").format("M");
  const twoMonthLater = _date.add(2, "M").startOf("month").format("M");
  const threeMonthLater = _date.add(3, "M").startOf("month").format("M");
  const nextMonthDay = _date.add(1, "M").startOf("month").format("YYYY-MM-DD");
  const twoMonthLaterDay = _date.add(2, "M").startOf("month").format("YYYY-MM-DD");
  const threeMonthLaterDay = _date.add(3, "M").startOf("month").format("YYYY-MM-DD");
    
  return {
    day,
    fulldate,
    month,
    year,
    start,
    end,
    week,
    w,
    plusOneDay,
    minusOneDay,
    nextMonth,
    twoMonthLater,
    nextMonthDay,
    twoMonthLaterDay,
    threeMonthLater,
    threeMonthLaterDay,
  };
}
