<script setup>
import { inject, reactive, computed, watch, defineProps, toRefs } from "vue";
const props = defineProps({
    days: String
})
const { days } = toRefs(props)
const dayjs = inject("dayjs");
const calendarDates = reactive([])


const thisMonth = computed(() => {
    const thisDate = dayjs(props.days)
    const startDate = thisDate.startOf('month')
    const endDate = thisDate.endOf('month')
    const days = endDate.diff(startDate, 'day') + 1
    
    const Date = {
        month: thisDate.format('M') + '月',
        start: startDate.format('YYYY-MM-DD'),
        days: days,
    }
    return Date
})

const setCalendarDate = (target) => {
    calendarDates.splice(0)
    const thisMonth = dayjs(target.start)

    for (let i = 0; i < target.days; i++) {
        let dateclass = ''
        const date = thisMonth.add(i, 'days')
        if (Number(date.format('d')) === 0) {
            dateclass = 'sunday'
        } else if (Number(date.format('d')) === 6) {
            dateclass = 'saturday'
        }
        const dateData = {
            date: date.format('D'),
            class: dateclass,
            week: date.format('ddd'),
        }
        calendarDates.push(dateData)
    }
}
watch(days, () => {
    setCalendarDate(thisMonth.value)
})

setCalendarDate(thisMonth.value)

</script>

<template>
    <thead class="text-center">
        <tr>
            <th rowspan="2" class="race-header-month">
                <p class="text-xl">{{ thisMonth.month }}</p>
            </th>
            <td v-for="calendarDate in calendarDates" :key="calendarDate.date" class="race-header">
                {{ calendarDate.date }}
            </td>
        </tr>
        <tr>
            <td v-for="calendarDate in calendarDates" :key="calendarDate.date" class="race-header"
                :class="calendarDate.class">
                {{ calendarDate.week }}
            </td>
        </tr>
    </thead>
</template>
<style>
</style>