<script setup>
import { inject, defineProps, computed } from "vue"
import { useStore } from "vuex"
const store = useStore()
const props = defineProps({
    days: String,
    races:Array
})

const daysjs = inject("dayjs")


const days = computed({
    get: () => {
        const thisDate = daysjs(props.days)
        const startDate = thisDate.startOf('month')
        const endDate = thisDate.endOf('month')
        const days = endDate.diff(startDate, 'day') + 1
        const dayArr = []
        for (let i = 0; i < days; i++){
            const day = startDate.add(i, 'd').format('YYYY-MM-DD')
            dayArr.push(day)
        }
        return dayArr
    }
})

const racesCount = (date) => {
    const races = []
    props.races.forEach(e => {
        if (e.date === date) {
           races.push(e.id) 
        }
    })
    return races.length
}
const ngchk = (num) => {
    if (num === store.state.totalRaces || num === 0) {
        return true
    } else {
        return false
    }
}



</script>

<template>
    <tfoot class="text-center race-body-footer">
        <tr>
            <th class="race-header-month">レース数</th>
            <td v-for="day in days" :key="day" :class="{'ng':!ngchk(racesCount(day))}">
                {{ racesCount(day) }}
            </td>
        </tr>
       
    </tfoot>
</template>
<style>
.ng{
    color: rgb(255,0,0);
    font-weight: bold;
}

</style>