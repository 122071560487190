<template>
  <div>
    <router-view />
  </div>
</template>
<script>
export default {
  mounted() {
    this.$store.dispatch('stayUserAction')
    this.$store.dispatch('initStayAction')
  } 
}
</script>


